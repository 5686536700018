<div *ngIf="state === 'live' || state === 'pre-live'" class='mini_div'>
  <img
    (click)='setActiveSlide()'
    class='mini_slide'
    [ngClass]="{ 'mini-highlight': highlight }"
    src="{{ path }}slide-previews/{{ slide['slide'].split('.')[0] +'.webp'}}?{{noCacheNumber}}"
    loading='lazy'
    (error)='handleMissingSlide($event)'
  />
</div>
<div *ngIf="state === 'post-live'" class='mini_div'>
  <img
    (click)="playbackAtTimestamp(slide['tc'])"
    class='mini_slide'
    [ngClass]="{ 'mini-highlight': highlight }"
    src="{{ path }}slide-previews/{{ slide['slide'].split('.')[0] + '.webp'}}?{{noCacheNumber}}"
    loading='lazy'
    (error)='handleMissingSlide($event)'
  />
</div>
