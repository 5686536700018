import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  Input, OnChanges, OnDestroy,
  OnInit,
  Output, QueryList, SimpleChanges,
  ViewChild
} from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ISlide, ISlideObject, Slide, SlidePlaylist } from '../shared/slide';
import { Eventstatus } from '../shared/eventstatus';
import { EventconfigStoreService } from '../shared/eventconfig-store.service';
import { SlideStoreService } from '../shared/slide-store.service';
import { LogService } from '../shared/log.service';
import { VideoplayerComponent } from '../videoplayer/videoplayer.component';
import { PlayerStoreService } from '../shared/player-store.service';
import { environment } from '../../environments/environment';
import { Subscription } from 'rxjs';
import { SlidelistItemComponent } from './slidelist-item/slidelist-item.component';
import videojs from 'video.js';
import getPlayer = videojs.getPlayer;
@Component({
  selector: 'lse-slidelist',
  templateUrl: './slidelist.component.html',
  styleUrl: './slidelist.component.css',
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate('1.5s ease-in', style({ height: 0, opacity: 1 }))
      ]),
      transition(':leave', [
        style({ height: 0, opacity: 1 }),
        animate('1s ease-in', style({ height: 0, opacity: 0 }))
      ])
    ]),
    trigger('slideInOut', [
      state(
        'in',
        style({
          overflow: 'hidden',
          height: '*'
        })
      ),
      state(
        'out',
        style({
          overflow: 'hidden',
          height: '0px'
        })
      ),
      transition('in => out', animate('250ms ease-in-out')),
      transition('out => in', animate('250ms ease-in-out'))
    ])
  ]
})

export class SlidelistComponent implements OnInit, OnDestroy {
  @ViewChild('slideHistoryChild', { read: ElementRef })
  public slideHistoryChild: ElementRef<any>;
  @ViewChild('slideHistoryContent', { read: ElementRef })
  public slideHistoryContent: ElementRef<any>;
  @ViewChild('slideDiv') slideDiv: ElementRef;

  @Input() phase: string;
  @Input() eStatus: Eventstatus;
  @Input() eLangGlob: any;
  @Input() slideIsCustom: any;
  @Input() eventFolder: string;
  @Input() languages: string[];
  @Input() selectedLanguage: string;
  @Output() playAtEvent = new EventEmitter();
  @Output() showElement = new EventEmitter();

  // playlist: Slide[];
  path: string;
  hasScrolled = false;
  showSlideFlow = 'in';
  screenwidth = document.body.offsetWidth;

  private readonly subscriptions : Subscription[] = [];

  private slideStoreService: SlideStoreService = inject(SlideStoreService);
  private playerService: PlayerStoreService = inject(PlayerStoreService);

  public playlist: SlidePlaylist[] = [];
  public selectedSlideObject: SlidePlaylist;
  private playertime: number;


  constructor() {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.slideStoreService.playlist$.subscribe((playlist: SlidePlaylist[]) => {
        this.playlist = [...playlist];
      }),
      this.slideStoreService.selectedSlide$.subscribe((slide: SlidePlaylist) => {
        this.selectedSlideObject = slide;
      }),
      this.slideStoreService.scrollToSlide$.subscribe((slide) => {
        setTimeout(() => {
          this.scrollToSlide(slide);
        }, 100);
      }),
      this.playerService.currentTime$.subscribe((time) => {
        this.playertime = time;
        this.triggerSlide(); // Trigger slide change on time change
      })
    );
    setTimeout(() => {
      this.scrollToSlide(this.selectedSlideObject);
    }, 100);
  }

  get selectedSlideObjectIndex() {
    return this.playlist.findIndex((x) => x._id === this.selectedSlideObject?._id);
  }


  toggleSlideflow() {
    this.showSlideFlow = this.showSlideFlow === 'out' ? 'in' : 'out';
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenwidth = window.innerWidth;
  }


  selectSlide(slide: SlidePlaylist) {
    this.scrollToSlide(slide);
    this.slideStoreService.setSelectedSlide(slide);
  }


  scroll(direction: string) {
    console.log('scroll() direction:', direction);
    if (direction === 'left') {
      this.hasScrolled = true;
      this.slideHistoryContent.nativeElement.scrollTo({
        left: this.slideHistoryContent.nativeElement.scrollLeft - 150,
        behavior: 'smooth'
      });
    } else if (direction === 'right') {
      this.slideHistoryContent.nativeElement.scrollTo({
        left: this.slideHistoryContent.nativeElement.scrollLeft + 150,
        behavior: 'smooth'
      });
      if (
        this.slideHistoryContent.nativeElement.scrollLeft ===
        this.slideHistoryContent.nativeElement.scrollWidth -
        this.slideHistoryContent.nativeElement.clientWidth
      ) {
        this.hasScrolled = false;
      }
      if (this.phase === 'post-live') {
        this.hasScrolled = true;
      }
    }
  }

  scrollToSlide(slide: SlidePlaylist) {
    const index = this.playlist.findIndex((x) => x._id === slide?._id);
    const e =
      index *
      (this.slideHistoryContent.nativeElement.scrollWidth / this.playlist?.length) -
      (this.slideHistoryContent.nativeElement.offsetWidth / 2 - 70);
    this.slideHistoryContent.nativeElement.scrollTo({
      left: e,
      behavior: 'smooth'
    });
  }

  triggerSlide() {
    if (this.eStatus.phase === "post-live") {
      if (!this.playlist || this.playlist.length === 0) return;

      const currentTime = this.playertime;

      // Sort playlist by timestamp to ensure correct order
      const sortedPlaylist = [...this.playlist].sort((a, b) =>
        this.convertTimeToSeconds(a.timestamp) - this.convertTimeToSeconds(b.timestamp)
      );

      const matchingSlide = sortedPlaylist.find((slide, index) => {
        const currentSlideTime = this.convertTimeToSeconds(slide.timestamp);
        const nextSlide = sortedPlaylist[index + 1];
        const nextSlideTime = nextSlide
          ? this.convertTimeToSeconds(nextSlide.timestamp)
          : Infinity;

        return currentTime >= currentSlideTime && currentTime < nextSlideTime;
      });

      if (matchingSlide && matchingSlide._id !== this.selectedSlideObject._id) {
        this.slideStoreService.setSelectedSlide(matchingSlide);
      }
    }
  }

  private convertTimeToSeconds(timeString: string): number {
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

}

