import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, interval, map, Observable, Subject } from 'rxjs';
import { EventconfigStoreService } from './eventconfig-store.service';
import { environment } from '../../environments/environment';
import Player from 'video.js/dist/types/player';

@Injectable({
  providedIn: 'root'
})
export class PlayerStoreService {
  /**
   * Service für den Videoplayer.
   * Ruft Streaming- und VoD-Adressen ab und stellt die aktuelle Playtime für Nutzung in VoD-Komponenten bereit.
   */
  player: Player;
  private api = environment.apiUrl + 'api';
  currentPlayTime = 0;
  playerSrc: string;
  playerType: string;

  constructor(private http: HttpClient, private es: EventconfigStoreService) {
    interval(1000).pipe(
      map(() => this.player ? this.player.currentTime() : 0)
    ).subscribe(time => this.currentTimeSubject.next(time));
  }

  //
  private timeUpdateSource = new BehaviorSubject<number>(0);
  public timeUpdate$ = this.timeUpdateSource.asObservable();
  private currentTimeSubject = new BehaviorSubject<number>(0);
  public currentTime$: Observable<number> = this.currentTimeSubject.asObservable();

  setTime(time: number) {
    this.timeUpdateSource.next(time);
  }

  /**
   * Ruft aktuelle Streaming- und VoD-Adresse von API ab. Dazu ist die Initialisierung des Events notwendig.
   */
  getStreamSrc(addr: string): Observable<any> {
    return this.http.get(`${this.api}/api.php?x=get_stream&lang=${this.es.getActiveLang()}&addr=${addr}`, { responseType: 'json' });
  }

  /**
   * Speichert aktuelle Playtime in Variable.
   * @param currentTime: Übergebener Timecode, der als aktuelle Playtime des Videoplayers in Variable gespeichert werden soll.
   */
  setCurrentPlayTime(currentTime: number) {
    this.currentPlayTime = currentTime;
  }

  /**
   * Gibt aktuelle Playtime des Videoplayer zurück.
   */
  getCurrentPlayTime() {
    return this.currentPlayTime;
  }

  setPlayerSrc(src: string, type: string) {
    this.playerSrc = src;
    this.playerType = type;
  }

  getPlayerSrc() {
    return this.playerSrc;
  }

  getPlayerType() {
    return this.playerType;
  }

  writeLog() {
    return this.http.get(`${this.api}/api.php?x=logvod&lang=${this.es.getActiveLang()}`);
  }

  setPlayer(videoPlayer: Player) {
    this.player = videoPlayer;
  }

  getPlayer() {
    return this.player;
  }
}
