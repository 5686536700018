import { Component, EventEmitter, inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ISlide, ISlideObject, Slide, SlidePlaylist } from '../../shared/slide';
import { constants } from '../../_constant/constants';
import { environment } from '../../../environments/environment';
import { EventconfigStoreService } from '../../shared/eventconfig-store.service';
import { SlideStoreService } from '../../shared/slide-store.service';
import { timestamp } from 'rxjs';
import { PlayerStoreService } from '../../shared/player-store.service';

@Component({
  selector: 'lse-slidelist-item',
  standalone: false,
  template: `
    <div class='mini_div'>
      @defer (on viewport){
      <img
        (click)='playbackAtTimestamp(playlistObject?.timestamp)'
        [ngClass]="{ highlight: this.selectedSlideObject?._id === playlistObject?._id }"
        class='mini_slide'
        src='{{environment.apiUrl}}/event/{{this.eventFolder}}/{{constant.SLIDES_FOLDER_PATH}}/{{this.playlistObjectSource}}_200.webp'
      />
      } @placeholder (minimum 100ms){
        <img
          class='mini_slide'
          src='assets/images/placeholder.jpg'
        />
      }
    </div>
<!--
  <div *ngIf="state === 'post-live'" class='mini_div'>
    <img
      (click)="playbackAtTimestamp()"
      class='mini_slide'
      [ngClass]="{ 'mini-highlight': highlight }"
&lt;!&ndash;      src='{{environment.apiUrl}}/event/{{this.eventFolder}}/{{constant.SLIDES_FOLDER_PATH}}/{{getActiveSlideNEW()}}_200.webp'&ndash;&gt;
      height='70'
      width='120'
      loading='lazy'
      (error)='handleMissingSlide($event)'
    />
  </div>-->
`,
  styleUrl: './../slidelist.component.css'
})
export class SlidelistItemComponent implements OnInit {
  @Input() playlistObject: SlidePlaylist;
  @Input() eventFolder: string;
  @Input() path: string;
  @Input() languages: string[];
  @Input() selectedLanguage: string;
  @Input() selectedSlide: ISlideObject;
  @Input() selectedSlideObject: SlidePlaylist;
  @Input() selectedSlideObjectIndex: number;



  @Output() vodEvent = new EventEmitter();
  @Output() centerSlide = new EventEmitter();
  @Output() showElement = new EventEmitter();
  @Output() playAtTimestamp = new EventEmitter();


  protected readonly constant = constants;
  protected readonly environment = environment;

  state: string;
  highlight = false;
  slideDirection: string;

  eventConfigStoreService: EventconfigStoreService = inject(EventconfigStoreService);
  playerService: PlayerStoreService = inject(PlayerStoreService);

  ngOnInit(): void {
    this.getEventState();
    this.slideDirection = this.eventConfigStoreService.eState === 'live' ? 'rtl' : 'ltr';
  }

  getEventState() {
    this.state = this.eventConfigStoreService.getEventState();
  }

  playbackAtTimestamp(timestamp: string) {
    this.playerService.setTime(this.slideTCtoSeconds(timestamp));
    this.eventConfigStoreService.setActiveSlideNEW(this.playlistObject);
  }

  slideTCtoSeconds(tc: string): number {
    let slideTimeCodeArray = tc.split(':');
    return (Number.parseInt(slideTimeCodeArray[0]) * 60 +
        Number.parseInt(slideTimeCodeArray[1])) * 60 +
      Number.parseInt(slideTimeCodeArray[2]);
  }

  get playlistObjectSource(): null | string {
    if (!this.playlistObject) {
      return null;
    } else

    // First try: Check selected language
    if (this.playlistObject.slide[this.selectedLanguage]) {
      return this.playlistObject.slide[this.selectedLanguage];
    } else

    // Second try: Check first language from languages array
    if (this.languages?.length > 0 && this.playlistObject.slide[this.languages[0]]) {
      return this.playlistObject.slide[this.languages[0]];
    } else

    // Third try: Check second language from languages array
    if (this.languages?.length > 1 && this.playlistObject.slide[this.languages[1]]) {
      return this.playlistObject.slide[this.languages[1]];
    }

    // If nothing found, return null or a default value
    return null;
  }



}
