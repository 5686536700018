import {
  AfterViewInit,
  Component,
  ElementRef, inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { EventconfigStoreService } from '../shared/eventconfig-store.service';
import { LogService } from '../shared/log.service';
import { PlayerStoreService } from '../shared/player-store.service';
import { Eventstatus } from '../shared/eventstatus';
import { environment } from '../../environments/environment';
import videojs from 'video.js/dist/video.min.js';
import SourceMenuButton from './videoplayer-plugins/source-menu-button';
import {UserAssignService} from "../shared/user-assign.service";
import { Subscription } from 'rxjs';
@Component({
  /**
   * Videoplayer(VJS)-Komponente (FR - 2021/02/10)
   * Die Komponente liefert den Videoplayer für Livestream und VoD aus.
   * Es wird die Angularversion des VJS-Player genutzt.
   * Die CSS sollte in separater CSS ./videoplayer.component.css angepasst werden, nicht in der videojs.css.
   * Bei Initialisierung wird die aktuelle Streaming-Adresse bzw. der Videolink aus dem Videoplayerservice abgerufen.
   */
  selector: 'lse-videoplayer',
  template: `
    <video
      #target
      class='video-js'
      [ngClass]="{'live': eStatus.phase === 'live'}"
      controls
      playsinline
      preload='metadata'
    ></video>
  `,
  styleUrls: ['./videoplayer.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class VideoplayerComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('target', { static: true }) target: ElementRef;
  //@Injectable({providedIn: 'root'});
  // see options: https://github.com/videojs/video.js/blob/mastertutorial-options.html
  @Input() stream;
  @Input() eStatus: Eventstatus;
  @Input() options: {
    maxQuality?: number;
    poster: any;
    sources: any;
    fluid?: boolean;
    controls?: boolean;
    controlBar?:
      {
        progressControl:
          { seekBar: boolean; };
      };
    autoplay?:
      boolean; playbackRates?:
      number[]; liveui?:
      boolean;
    html5?:
      {
        vhs:
          { overrideNative: boolean; };
      };
  };

  posterPic: string;
  environmentVars = environment;
  player;
  eStream = '';
  playtimeInterval: any;
  srcInterval: any;
  eType = 'video/mp4';
  ePhase: string;
  maxQuality: number;
  isInternal: boolean;
  subscription: Subscription[];

  constructor(
    private elementRef: ElementRef,
    private vs: PlayerStoreService,
    private es: EventconfigStoreService,
    private logger: LogService,
    private userService: UserAssignService
  ) {
  }


  ngOnInit() {
    this.isInternal = this.userService.getInternal();
    this.ePhase = this.es.getEventState();
    this.options = this.getPlayerOptions(this.eStatus.phase);
    if (this.options.poster === '' || this.options.poster === undefined) {
      this.options.poster =
        this.environmentVars.cssPrefix +
        'assets/images/' +
        this.environmentVars.customer +
        '/live_' +
        this.es.getActiveLang() +
        '.png';
    }
    // instantiate Video.js
    this.getPlayerSrc();
    this.logger.log('Stream: ' + this.eStream + ' Type ' + this.eType);
    if (this.stream.vodPath.includes('Transkodiert') && this.es.getEventState() === 'post-live') {
      this.options.poster = this.stream.vodPosterUrl;
      this.options.sources[0] = {
        src: this.eStream,
        type: this.eType
      };
    } else {
      this.options.sources[0] = {
        // #t=0.5 um den ersten Frame des Postlive Videos als Poster zu setzen
        src: this.eStream + '#t=0.5',
        type: this.eType
      };
    }
    this.player = videojs(this.target.nativeElement, this.options);
    if (!this.stream.vodPath.includes('Transkodiert') && this.es.getEventState() === 'post-live') {
      this.replacePosterFromMedienFolder();
    }

     this.player.options_.maxQuality = this.isInternal  ? this.stream.max_quality_level_int : this.stream.max_quality_level_ext;
    if (!this.player.options_.maxQuality) {
      this.player.options_.maxQuality = 720;
    }
    this.player.on('loadedmetadata', () => {
      this.player.options_.userSelectedQuality = this.player.qualityLevels().length;
      const controlBar = this.player.controlBar;
      const fullscreenToggle = controlBar.getChild('fullscreenToggle');
      if (this.player.videojs_http_source_selector_initialized == 'undefined' || this.player.videojs_http_source_selector_initialized == true) {

      } else if (this.player.qualityLevels().levels_.length > 1) {
        this.player.videojs_http_source_selector_initialized = true;
        controlBar.el().insertBefore(controlBar.addChild(new SourceMenuButton(this.player, this.options)).el(), fullscreenToggle.el());
      }
    });
    this.player.on('ready', () => {
      this.player.pause();
      this.logger.log('Player ready, Start Logging');
      if (this.es.getEventState() === 'post-live') {
        this.vs.writeLog().subscribe(() => {
          this.logger.log('Try Logging');
        });
      }
    });
    this.player.on('playerresize', () => {
      console.log('Player resized');
      this.player.options_.userSelectedQuality = this.player.qualityLevels().length;
      this.player.qualityLevels().trigger('change');
    });


    // Interval um alle Sekunde die aktuelle Playtime im Service zu setzen.
    this.playtimeInterval = setInterval(() => {
      this.mySetPlayTime();
      if (this.es.getEventState() === 'pre-live') {
        this.player.pause();
      }
      if (this.es.getEventState() === 'live' && this.ePhase === 'pre-live') {
        this.player.play();
      }
      if (this.es.getEventState() === 'post-live' && !this.eStatus.player) {
        this.player.pause();
      }
      this.ePhase = this.es.getEventState();
    }, 1000);
    this.srcInterval = setInterval(() => {
      this.getPlayerSrc();
    }, 10000);
    this.vs.setPlayer(this.player);
  }

  ngAfterViewInit(): void {
    if (this.ePhase === 'live') {
      const seekBar = document.getElementsByClassName('vjs-progress-control vjs-control')[0] as HTMLElement;
      seekBar.style.cursor = 'default';
    }
  }

  /**
   * Startet den Player an vorgebener Zeit. Funktion für VoD. Wird über Eventemitter der Fragenliste und der Slides ausgelöst.
   * @param time: Zeit in Sekunden, an der das Video in VoD abgespielt wird.
   */
  playAt(time) {
    this.logger.log('CurrentTime');
    this.player.currentTime(time);
  }

  /**
   * Funktion gibt den aktuellen Timecode in Sekunden zurück.
   */
  myGetPlayTime() {
    return this.player.currentTime();
  }


  /**
   * Setzt den aktuellen Timecode im Playerservice.
   */
  mySetPlayTime() {
    this.vs.setCurrentPlayTime(this.myGetPlayTime());
  }

  getPlayerSrc() {
    let oldSrc = '';
    let oldSrcRaw = '';
    let eStreamRaw: string;
    if (this.eStream !== '' && this.eStream !== undefined) {
      oldSrc = this.eStream;
      oldSrcRaw = oldSrc.split('.mp4')[0];
    }
    this.eStream = this.vs.getPlayerSrc();
    if (oldSrc !== this.eStream && oldSrc !== '') {
      this.player.options_.maxQuality = this.isInternal && !this.eStream.includes("wow-01")&& !this.eStream.includes("wow-02")&& !this.eStream.includes("cdn3.wowza") ? this.stream.max_quality_level_int : this.stream.max_quality_level_ext;
      this.player.qualityLevels().trigger("change");
      if(!this.player.options_.maxQuality){
        this.player.options_.maxQuality=720;
      }
    }
    this.eType = this.vs.getPlayerType();

    eStreamRaw = this.eStream.split('.mp4')[0];

    if (eStreamRaw !== oldSrcRaw && oldSrcRaw !== '') {
      this.logger.log('Changing Src. Src: ' + this.eStream + ' Type: ' + this.eType);
      this.player.pause();
      // this.options.sources[0] = {src: this.eStream, type: this.eType};
      this.player.src({ type: this.eType, src: this.eStream });
    }
  }

  getPlayerOptions(phase: string) {
    console.log('getPlayerOptions @ Time of initiation:', phase);
    return {
      fluid: true,
      controls: true,
      controlBar: {
        progressControl: {
          seekBar: phase === 'post-live' // true:false is redundant but makes it clearer
        }
      },
      autoplay: true,
      playbackRates: phase === 'post-live' ? [0.75, 1, 1.25, 1.5, 2] : [], // usePlaybackrates if POST-LIVE,
      liveui: true,
      html5: {
        vhs: {
          overrideNative: true
        }
      },
      poster: this.posterPic,
      sources: [{ src: this.eStream, type: 'application/x-mpegURL' }]
    };
  }

  updateQuality(maxQuality) {
    this.player.options_.userSelectedQuality = this.player.qualityLevels().length;
    if (this.isInternal) {
      this.player.options_.maxQuality = maxQuality.int;
      this.player.qualityLevels().trigger('change');
    } else {
      this.player.options_.maxQuality = maxQuality.ext;
      this.player.qualityLevels().trigger('change');
    }
  }

// replace poster with custom image form "Medien" folder
// jpg and png
  replacePosterFromMedienFolder() {
    this.checkIfImageExists(this.stream.vodPath.replace('.mp4', '.jpg'), (jpgExists) => {
      if (jpgExists) {
        this.player.poster_ = this.stream.vodPath.replace('.mp4', '.jpg');
      }
      this.player.trigger('posterchange');
    });
  }

// check if custom poster exists
  checkIfImageExists(url, callback) {
    const img = new Image();
    img.src = url;

    if (img.complete) {
      callback(true);
    } else {
      img.onload = () => {
        callback(true);
      };

      img.onerror = () => {
        callback(false);
      };
    }
  }

  ngOnDestroy() {
    // destroy player
    if (this.player) {
      this.player.dispose();
    }
    this.player.off('playerresize', () => {
      this.player.options_.userSelectedQuality = this.player.qualityLevels().length;
      this.player.qualityLevels().trigger('change');
    });
  }
}
